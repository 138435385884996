<template>
  <div class="bg-login fill-height">
    <v-container fluid class="fill-height">
      <v-row>
        <v-col cols="12">
          <div class="d-flex justify-center">
            <login-card>
              <login-form></login-form>
            </login-card>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
const LoginCard = () => import("../cards/LoginCard.vue");
const LoginForm = () => import("../forms/LoginForm.vue");
export default {
  components: {
    LoginCard,
    LoginForm
  }
};
</script>
